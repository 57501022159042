*,
*::before,
*::after {
  box-sizing: border-box;
}

.form-element {
  margin-top: 10px;
  margin-bottom: 10px;
  .select {
    display: flex;
    justify-content: center;
    width: 85%;
    height: 45px;
    background-color: var(--input-color);
    border: none;
    border-bottom: 1px solid var(--color-primary);
    font-family: Afek-Regular-otf;
    @media screen and (max-width: 992px) {
      width: 100%;
    }
  }
  .MuiInputBase-root {
    font-family: Afek-Regular-otf;
    border: none;
  }
  .MuiSelect-select {
    border: none;
  }
  .MuiSelect-icon {
    position: absolute;
    left: 0;
    right: 90%;
  }
  .Mui-selected {
    background-color: var(--input-color);
  }

}
