.footer {
  background-color: var(--color-primary);
  text-align: right;
  padding: 40px;
  @media screen and (min-width: 993px) {
    display: grid;
  }
  .footer-container {
    @media screen and (min-width: 993px) {
      padding-top: 2rem;
      padding-bottom: 5rem;
      margin: auto;
    }

    .links {
      @media screen and (min-width: 993px) {
        display: flex;
        gap: 12rem;
      }
      .links-container {
        margin-bottom: 30px;
        .link-list {
          padding-right: 0px;
          line-height: 1.8;
          list-style: none;
          @media screen and (min-width: 993px) {
            margin-top: 0;
          }
          li {
            box-sizing: border-box;
            a {
              text-decoration: underline;
              text-underline-offset: 0.7ex;
              color: var(--text-color);
            }
          }
        }
        .follow-us {
          color: var(--text-color);
          font-family: Afek-Regular-otf;
          font-size: 15px;
          @media screen and (min-width: 993px) {
            margin-top: 0;
            font-size: 22px;
          }
        }
      }
    }

    .share-buttons {
      .share-buttons-list {
        list-style: none;
        display: flex;
        gap: 1.625rem;
        flex-wrap: wrap;
        padding-right: 0px;
        .share-buttons-item {
          .share-buttons-link {
            color: var(--text-color);
            .sr-only {
              position: absolute;
              width: 1px;
              height: 1px;
              padding: 0;
              margin: -1px;
              overflow: hidden;
              clip: rect(0, 0, 0, 0);
              white-space: nowrap;
              border: 0;
            }
            .vi-icon {
              display: inline-block;
              width: 34px;
              height: 34px;
              overflow: hidden;
              fill: currentColor;
              flex-shrink: 0;
              @media screen and (min-width: 993px) {
                width: 44px;
                height: 44px;
              }
            }
          }
        }
      }
    }
    .copyright {
      color: var(--text-color);
      font-family: Afek-Regular-otf;
    }
  }
}
