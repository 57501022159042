@font-face {
  font-family: 'Afek-Regular';
  src: url('./assets/fonts/Afek-Regular-5c78f23ec16e86a0488ccad10b3c7717.woff') format('woff');
}
@font-face {
  font-family: 'Afek-Regular-otf';
  src: url('./assets/fonts/afek-regular-aaa.otf');
}

:root {
  --color-primary: #11562c;
  --color-primary-2: #B8D37E;
  --color-secondary-1: #E91E76;
  --text-color: white;
  --input-color: #ecf1ee;
  --file-name: #00a03b;
}

body {
  margin: 0;
  direction: rtl;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family:'Afek-Regular-otf';
    font-size: 14px;
    line-height: 1.625;
    color: var(--color-primary);
    @media screen and (min-width: 993px) {
      font-size: 20px;
    }
}
*, *::before, *::after {
  box-sizing: border-box;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.invalid-feedback{
  color: #fb3449;
  font-size: 10px;
  font-family:'Afek-Regular-otf';
  @media screen and (min-width: 993px) {
    font-size: 15px;
    font-family:'Afek-Regular-otf';
  }
}

label {
  display: block;
  align-items: center;
  color: var(--color-primary);
}
button{
  font-family:'Afek-Regular-otf';
}
.must-text {
  font-size: 11px;
  line-height: 1.7;
  opacity: 0.8;
}

.flex {
  display: flex;
}

.column {
  display: flex;
  flex-direction: column;
}

.space-between {
  display: flex;
  justify-content: space-between;
}

.center {
  align-items: center; 
}