.form-element {
  margin-top: 10px;
  margin-bottom: 10px;
  .form-input {
    display: block;
    width: 85%;
    height: 45px;
    background-color: var(--input-color);
    border: none;
    border-bottom: 1px solid var(--color-primary);
    font-family: Afek-Regular-otf;
    @media screen and (max-width: 992px) {
      width: 100%;
    }
  }
}
.checkbox-container {
  display: flex;
  justify-content: flex-end;
  flex-direction: row-reverse;
  margin-bottom: 16px;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}
