a {
  color: var(--color-secondary-1);
  text-decoration: underline;
  background-color: transparent;
  transition: color .3s;
}
label{
  display: inline-block;
}
p{
  @media screen and (min-width: 993px) {
  width: 90%;
  }
  .must-text{
    margin-right: 3px;
  }
}
.disabled-checkbox-label{
  position: relative;
  margin-bottom: 0;
  vertical-align: top;
  color: #b4b4b4;
}
